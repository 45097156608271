import React, { useState } from "react";
import { Link } from "react-router-dom";
import menu from "../../assets/pictures/menu-icon-svg.svg";
import "./Navbar.css";
import "./Navbar.mobile.css";
import entrustLogo from "../../assets/pictures/navbar-logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuTranslate, setMenuTranslate] = useState("translateX(220px)");
  const [burgerButtonTranslate, setBurgerButtonTranslate] =
    useState("translate(30px)");

  const handleMenuClick = () => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      setMenuTranslate("translateX(0px)");
      setBurgerButtonTranslate("translateX(-90px)");
    } else if (isMenuOpen) {
      setIsMenuOpen(false);
      setMenuTranslate("translateX(220px)");
      setBurgerButtonTranslate("translateX(30px)");
    }
  };

  const desktopNavbar = (
    <div className="navbar-box">
      <div className="navbar-desktop ">
        <div className="container ">
          <div className="row">
            <div className="col">
              <img src={entrustLogo} alt="Logo"/>
            {/* </div>
            <div className="col"> */}
              <ul className="navbar-layout">
                <li>
                  <Link to="/" className="navbar-text">
                    <p>
                      <span>HOME</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUS" className="navbar-text">
                    <p>
                      <span>ABOUT US</span>
                    </p>
                  </Link>
                </li>

                <li>
                  <Link to="/services" className="navbar-text">
                    <p>
                      <span>SERVICES</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <a
                    // href="https://api.whatsapp.com/send?phone=971561951285"
                    className="navbar-button"
                  >
                    <button>CONTACT US</button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const mobileNavbar = (
    <div className="navbar-mobile">
      <div className="container">
        <div className="row">
          <div className="col">
            <img
              src={menu}
              alt="Burger menu"
              className="navbar-menu"
              onClick={handleMenuClick}
              style={{ transform: burgerButtonTranslate }}
            ></img>

            <ul className="navbar-layout" style={{ transform: menuTranslate }}>
              <li className="navbar-text-home">
                <Link to="/" className="navbar-text ">
                  <p>
                    <span>HOME</span>
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/aboutUs" className="navbar-text">
                  <p>
                    <span>ABOUT US</span>
                  </p>
                </Link>
              </li>
              <li>
                <Link to="/services" className="navbar-text">
                  <p>
                    <span>SERVICES</span>
                  </p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {mobileNavbar}
      {desktopNavbar}
    </div>
  );
};

export default Navbar;
