import React from "react";
import "./AboutUs.css";
import "./AboutUs.mobile.css"
import aboutUS from "../../assets/pictures/about us.png";
import towerImg from "../../assets/pictures/aboutUs-towers-pic.png";
import ourTeamIcon from "../../assets/pictures/our-team-icon.png";

const AboutUs = () => {
  return (
    <div className="aboutUs-background">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="aboutUs-header">
              <img src={aboutUS} alt="People avatar" />
              <h1>ABOUT US</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="aboutUs-content">
              <p>
                {" "}
                We, as ENTRUST Re, guide you to pinpoint the right reinsurance
                support with reputable and reliable reinsurance companies, which
                provide precise coverage, sustenance, and pricing that best
                suits your needs with leading global insurance and reinsurance
                markets. We have more than 28 years of experience in the fields
                of global Insurance, Reinsurance, Niche products, and Risk
                management. We are professionals at negotiating coverage rates
                and finding the best support for you!
              </p>
              <p>
                In ENTRUST Re, we constantly study the insurance and reinsurance
                marketplaces to stay tuned to any evolving coverage changes,
                trends, programs, and policy enhancements across numerous
                markets and offer companies appropriate insurance plans. This
                empowers our partners to have access to the most current,
                optimal, creative, cost-effective insurance and risk management
                solutions for specialized insurance know-how in the Middle East,
                GCC, Asia, and Africa.
              </p>
              <p>
                Since our inception in 1994, we gladly adopted an attitude
                toward discovery, exploration, and everlasting development.
                Staying true to our heritage, our people and technology remain
                at the forefront of industry innovation, pioneering solutions
                that simplify the re-insurance experience, building trust and
                reducing uncertainty.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <img className="tower-img" src={towerImg} alt="City at night"/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="aboutUs-content-our-team">
              <img src={ourTeamIcon} alt="Peoples avatar" />
              <h2>OUR TEAM</h2>
              <p>
                Our skilled team is committed to exceeding our customers’
                expectations in developing a strategic alliance with the global
                insurance industry and a solid reputation that will enable the
                growth of business relationships with our partners based on
                expertise, awareness, and ongoing attention to their needs.
              </p>
            </div>
          </div>
          <div className="col-6">
            {/* <img className="aboutUs-dotted-img" src={dottedPic}/> */}
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
};
export default AboutUs;
