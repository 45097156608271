import React from "react";
import "./Home.css";
import "./Home.mobile.css";
import lamp from "../../assets/pictures/lamp-icon.png";
import balance from "../../assets/pictures/balance-icon.png";
import settingPic from "../../assets/pictures/setting-icon-with-background.png";
import clock from "../../assets/pictures/clock-icon.png";
import riskImage from "../../assets/pictures/risk-pic.png";
import khalijiImage from "../../assets/pictures/people-pic.png";
import networkIcon from "../../assets/pictures/network-icon.png";
import brainStorm from "../../assets/pictures/brainstorm-pic.png";
import dartIcon from "../../assets/pictures/dart-icon.png";
import meetingPic from "../../assets/pictures/meeting-pic.png";
import connectIcon from "../../assets/pictures/connect-icon.png";
import settingManIcon from "../../assets/pictures/setting-man-icon.png";
import chartIcon from "../../assets/pictures/chart-icon.png";
import targetIcon from "../../assets/pictures/target-icon.png";
import lampSvg from "../../assets/pictures/lamp-svg-icon.svg";
import balanceSvg from "../../assets/pictures/balance-svg-icon.svg";
import settingSvg from "../../assets/pictures/setting-svg-icon.svg";
import clockSvg from "../../assets/pictures/clock-svg-icon.svg";

const Home = () => {
  return (
    <>
      <div className="home-landing-container">
        <div className="container">
          <div className="row">
            <div className="col ">
              <div className="home-landing-content">
                <div className="home-landing-left-border">
                  <h1>Entrust Re</h1>
                  <p>
                    Your Guidance To Pinpoint The Right<br></br> Reinsurance
                    Support!
                  </p>
                </div>

                <div className="home-landing-icons">
                  <img src={lampSvg} alt="Lamp"/>
                  <img src={balanceSvg} alt="Balance icon"/>
                  <img src={settingSvg} alt="Settings icon"/>
                  <img src={clockSvg} alt="Clock icon"/>
                </div>
              </div>
            </div>

            {/* Add the line beside the heading */}
          </div>
        </div>
      </div>
      {/* Second Container part 1*/}
      <div className="container">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-6 col-sm-12 ">
            <img className="risk-img" src={riskImage} alt="Risk management" />
          </div>

          <div className="col-lg-6 col-sm-12 ">
            <div className="home-content-risk">
              <img src={lamp} alt="Lamp icon"/>
              <h2 className="spearHeading">SPEARHEADING INNOVATION</h2>
              <p>
                More than 28 years of experience in the fields of <br></br>{" "}
                global insurance,Reinsurance,Niche products,and <br></br> Risk
                management.
              </p>
            </div>
          </div>
        </div>
        {/* Second container part 2 */}
        <div className="row pb-5">
          <div className="col-lg-6 col-sm-12">
            <div className="home-content-stability">
              <img src={balance} alt="Balance icon"/>
              <h2>IN REALTIONSHIPS, STABILITY AND TRUST ARE FUNDAMENTAL!</h2>
              <p>
                We build long-term realationships and rapport with ceding
                companies and reinsurances.That's why we've teamed up with
                top-rated re-insurance companies to offer specialized products
                that may cater to each client's unique needs.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <img src={khalijiImage} className="khalijiImage" alt="Gulf people"/>
          </div>
        </div>
      </div>
      {/* end of PART 2 */}
      <div className="home-container-full-blue">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="home-content-blue">
                <img src={settingPic} alt="Settings icon"/>

                <h1>NEW POSSIBILITIES AWAIT...</h1>
                <p>
                  We drive business growth through forging strong Strategic
                  tie-ups and <br></br> Alliances for Re-Insurance Products
                  distribution
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Third Container */}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="home-content-time">
              <img src={clock} alt="Clock icon"/>
              <h2>TIME IS PRICLESS</h2>
              <p>
                The process of purchasing reinsurance can be time-consuming and
                very complicated.<br></br>
                For the reason, many insurance companies rely on the skills and
                expertise of reinsurance brokers.
              </p>
            </div>
          </div>
        </div>
        <div className="home-horizontal-line"></div>
        {/* Fourth Container */}
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="home-content-brainstorm">
              <img src={networkIcon} alt="Networking icon"/>
              <h2>
                WHEN EXPERTISE & SKILLS MEET, <br></br>
                PROGRESS IS INEVITABLE!
              </h2>
              <p>
                - We push you to grow or multiply in terms of the volume of
                premium. <br></br>- We strive to increase your capacity to
                assume more risks and to issue more policies.<br></br>- We
                provide great stability to the profits of the insurance
                business.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <img className="home-brainstorm-image" src={brainStorm} alt="Brain network"/>
          </div>
        </div>
        <div className="home-horizontal-line"></div>
        {/* Fifth Container */}
        <div className="row">
          <div className="col-4">
            <div className="home-mission-image">
              <img src={dartIcon} alt="Dart icon"/>
              <h2>
                ON A <br></br> MISSION TO
              </h2>
            </div>
          </div>

          <div className="col-8">
            <div className="home-mission-content">
              <ul>
                <li>
                  <h3>Securing sustainable reinsurance capacities</h3>
                </li>
                <p>
                  by aligning risk appetite and Portfolio Objectives with
                  profitability through secure lined and dynamic processes.
                </p>

                <div className="home-horizontal-line"></div>
                <li>
                  <h3>Reinsurance Expertise and Technical Advice</h3>
                </li>
                <p>
                  By assisting our business partners to thrive through
                  customized integrated solutions.{" "}
                </p>
                <div className="home-horizontal-line"></div>
                <li>
                  <h3>We speak your language</h3>
                </li>
                <p>
                  We negotiate on the technical aspects of reinsurance pricing
                  and underwriting considerations to obtain the most competitive
                  rates and terms for the client.
                </p>
              </ul>
            </div>
          </div>
          <div className="home-horizontal-line"></div>
        </div>
        {/* Fifth Container */}
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <img className="meeting-image" src={meetingPic} alt="Business meeting"/>
          </div>
          <div className="col-lg-6 .order-first-col-sm-12">
            <div className="home-content-meeting ">
              <img src={connectIcon} alt="Connect icon"/>
              <h2>MARKETING INTELLIGENCE</h2>
              <p>
                We can gather and monitor data to provide trends and
                opportunities for both insures and reinsurers.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Sixth Container */}
      <div className="home-people-meeting">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>MAKE NO WORRIES</h1>
              <p>
                We are experts in handling the Adminstration of Reinsurance{" "}
                <br></br>
                Contracts and Settlement of Balances (Premiums,Claims,Fees).
              </p>
              <img src={settingManIcon} alt="Man gear icon"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pb-5">
          <div className="col-lg-6 col-sm-12">
            <div className="home-filing-content">
              <img src={chartIcon} alt="Chart icon"/>
              <h2>FILING A CLIAM...</h2>
              <p>
                We handle and audit claims through prompt and proper liaizing
                with reinsurers ,ceding companies,and loss adjustors.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="home-target-content">
              <img src={targetIcon} alt="Tracking icon"/>
              <h2>KEEP ON TRACKING</h2>
              <p>
                We continue to advise on and draft new contacts, collect
                payments, and offer support with claims.We also continue to
                support the insured once the contract ends with reporting of
                liabilities and closing open claims.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
