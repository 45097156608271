import React from "react";
import "./Services.css";
import "./Services.mobile.css"
import papersIcon from "../../assets/pictures/papers-icon.png";

const Services = () => {
  return (
    <div className="services-background">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="services-landing">
              <img src={papersIcon} alt="Paper icon"/>
              <h1>BEYOND BROKING SERVICES</h1>
              <p>
                The Company combines a mix of capabilities and diverse expertise
                ranging from Consultancy and Claims Handling to
                Insurance and Reinsurance Technical and Operational support.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div class="shadow-lg p-3 mb-5 bg-body rounded services-treaty-box">
              <p>TREATY</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <div class="shadow-lg p-3 mb-5 bg-body rounded first-four-boxes">
              <p>Proportional All lines</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12">
            <div class="shadow-lg p-3 mb-5 bg-body rounded first-four-boxes">
              <p>Excess of Loss All lines</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12">
            <div class="shadow-lg p-3 mb-5 bg-body rounded first-four-boxes">
              <p>Stop All lines</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-12">
            <div class="shadow-lg p-3 mb-5 bg-body rounded first-four-boxes blue-box">
              <p>FACOB All Lines</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div class="shadow-lg  p-3 mb-5 bg-body rounded services-facultative-box">
              <p>FACULTATIVE</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-12 services-boxes-view-more ">
            <div class="shadow-lg p-3 mb-5 bg-body rounded">
              <p>PROPERTY AND ENGINEERING</p>
              <button
                type="button"
                class="btn btn-secondary"
                data-toggle="modal"
                data-target=".property-engineering"
              >
                View more
              </button>
            </div>
          </div>

          <div
            class="modal fade property-engineering"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col">
                    <div className="services-modal">
                      <p>
                        <strong>Property and Engineering:</strong>
                      </p>
                      <p>-&nbsp;&nbsp; Property All Risks</p>
                      <p>-&nbsp;&nbsp; Fire &amp; Allied Perils</p>
                      <p>-&nbsp;&nbsp; Political Risks</p>
                      <p>-&nbsp;&nbsp; Political Violence</p>
                      <p>-&nbsp;&nbsp; Sabotage &amp; Terrorism</p>
                      <p>-&nbsp;&nbsp; Contractors All Risk</p>
                      <p>-&nbsp;&nbsp; Erection All Risks</p>
                      <p>-&nbsp;&nbsp; Electronic Equipment</p>
                      <p>-&nbsp;&nbsp; Contractors Plant &amp; Machinery</p>
                      <p>-&nbsp;&nbsp; Machinery Breakdown</p>
                      <p>-&nbsp;&nbsp; Jewelers Block</p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 services-boxes-view-more">
            <div class="shadow-lg p-3 mb-5 bg-body rounded">
              <p>POLITICAL VIOLENCE (PV)</p>
              <button
                type="button"
                class="btn btn-secondary"
                data-toggle="modal"
                data-target=".political-violence"
              >
                View more
              </button>
            </div>
          </div>

          <div
            class="modal fade political-violence"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col">
                    <div className="services-modal">
                      <p>
                        <strong>Political Violence (PV)</strong>
                      </p>
                      <p>
                        Attacks by terrorists, acts of sabotage, war, and
                        political unrest, are on the rise around the globe and
                        constitute a serious and unpredictably high risk to the
                        assets of a person or a company.
                      </p>
                      <p>
                        Entrust RE offers Political Violence insurance which
                        provides coverage against and protection for:
                      </p>
                      <ul>
                        <li>
                          Physical Damage and subsequent Business Interruption /
                          Loss of Rent / Loss of Profit / Contingent Business
                          Interruption/ Denial of Access
                        </li>
                        <li>
                          Physical Damage on Construction Risk and Delay in
                          Start-Up
                        </li>
                        <li>
                          Third Party Liability to cover Third Party Property
                          Damage and Bodily Injury Due to the following perils:
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                      <p>-&nbsp;&nbsp; Terrorism</p>
                      <p>-&nbsp;&nbsp; Sabotage</p>
                      <p>-&nbsp;&nbsp; Riots, Strikes and/or Civil Commotion</p>
                      <p>-&nbsp;&nbsp; Malicious Damage</p>
                      <p>
                        -&nbsp;&nbsp; Insurrection, Revolution, or Rebellion
                      </p>
                      <p>-&nbsp;&nbsp; Mutiny and/or Coup d&rsquo;Etat</p>
                      <p>-&nbsp; &nbsp;War and/or Civil War</p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 services-boxes-view-more">
            <div class="shadow-lg p-3 mb-5 bg-body rounded">
              <p>CASUALITY & FINANCIAL LINES</p>
              <button
                class="btn btn-secondary"
                data-toggle="modal"
                data-target=".casualty-financial-lines"
              >
                View more{" "}
              </button>
            </div>
          </div>
        </div>

        <div
          class="modal fade casualty-financial-lines"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div className="container">
                <div className="row">
                  <div className="col">
                  <div className="services-modal">

                    <p>
                      <strong>Casualty</strong>&nbsp;
                      <strong>&amp; FINANCIAL LINES</strong>
                    </p>
                    <p>-&nbsp;&nbsp; Banker&rsquo;s Blanket Bond</p>
                    <p>-&nbsp;&nbsp; Electronic and Computer Crime</p>
                    <p>-&nbsp;&nbsp; Cyber Liability</p>
                    <p>-&nbsp;&nbsp; Defense Base Act</p>
                    <p>-&nbsp;&nbsp; Airside Liability</p>
                    <p>-&nbsp;&nbsp; Inherent Defect Ins.</p>
                    <p>-&nbsp;&nbsp; Medical Malpractice</p>
                    <p>-&nbsp;&nbsp; Event Liability</p>
                    <p>-&nbsp;&nbsp; Event Cancellation</p>
                    <p>-&nbsp;&nbsp; General Liability</p>
                    <p>-&nbsp;&nbsp; Product Liability</p>
                    <p>-&nbsp;&nbsp; Product Recall</p>
                    <p>
                      -&nbsp;&nbsp; Professional Indemnity (Annual &amp; Single
                      Projects)
                    </p>
                    <p>-&nbsp;&nbsp; Extended Warranty</p>
                    <p>-&nbsp;&nbsp; Workmen&rsquo;s Compensation</p>
                    <p>-&nbsp;&nbsp; Employers&rsquo; Liability</p>
                    <p>
                      -&nbsp;&nbsp; Directors &amp; Officers Liability
                      (Commercial &amp; Financial Institutions)
                    </p>
                    <p>-&nbsp;&nbsp; Motor High Valued Vehicles</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-12 services-boxes-view-more">
            <div class="shadow-lg p-3 mb-5 bg-body rounded">
              <p>LIFE & HEALTH</p>
              <button
                type="button"
                class="btn btn-secondary"
                data-toggle="modal"
                data-target=".life-health"
              >
                View more
              </button>
            </div>
          </div>
          <div
            class="modal fade life-health"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col">
                    <div className="services-modal">

                      <p>
                        <strong>Life & Health</strong>
                      </p>
                      <ol>
                        <li>Group Life</li>
                        <li>Medical Insurance</li>
                        <li>Death of any cause (Natural & Accidental)</li>
                        <li>Personal Accident including:</li>
                      </ol>
                      <p>-   Repatriation</p>
                      <p>-   Medical Expenses</p>
                      <p>
                        -   Temporary Total Disability due to sickness /
                        Accident
                      </p>
                      <p>
                        -   Permanent Total Disability due to sickness /
                        Accident
                      </p>
                      <p>
                        -   Permanent Partial Disability due to sickness /
                        Accident
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 services-boxes-view-more">
            <div class="shadow-lg p-3 mb-5 bg-body rounded">
              <p>MARINE,ENERGY & AVIATION</p>
              <button
                type="button"
                class="btn btn-secondary"
                data-toggle="modal"
                data-target=".marine-energy-aviation"
              >
                View more
              </button>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div
          class="modal fade marine-energy-aviation"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div className="container">
                <div className="row">
                  <div className="col">
                  <div className="services-modal">

                    <p>
                      <strong>Marine, Energy &amp; Aviation</strong>
                    </p>
                    <ol>
                      <li>
                        Marine Cargo including:
                        <p>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; -&nbsp;&nbsp; Single Shipments
                        </p>
                        <p>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; -&nbsp;&nbsp; Open Cover
                        </p>
                        <p>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; -&nbsp;&nbsp; Project Cargo
                        </p>
                        <p>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; -&nbsp;&nbsp; Delay in Start Up
                        </p>
                      </li>

                      <li>Marine Hull</li>
                      <li>Protection &amp; Indemnity (P &amp; I)</li>
                      <li>Pleasure Crafts including Mega Yachts</li>
                      <li>Builder&rsquo;s Risk</li>
                      <li>Ship Repairers&rsquo; Liability</li>
                      <li>Hauliers&rsquo; Liability</li>
                      <li>Freight Forwarders&rsquo; Liability</li>
                      <li>Terminal Operator&rsquo;s Liability</li>
                      <li>Stevedores&rsquo; Liability</li>
                      <li>Marina Liability</li>
                      <li>Aviation Hull</li>
                      <li>Aviation Liability</li>
                      <li>Energy (Upstream &amp; Downstream)</li>
                      <li>Kidnap &amp; Ransom</li>
                      <li>Credit Life</li>
                      <li>Critical Illness</li>
                    </ol>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* END OF CONTAINER */}
      </div>
    </div>
  );
};
export default Services;
